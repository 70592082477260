.main-footer {
  background-color: transparent;

  .nav-wrapper {
    padding: rem(40px) 0 rem(40px);

    &.secondary {
      background-color: transparent;

      .site-tools {
        width: auto;
      }

      p {
        font-size: rem(12px);
      }

      .container {
        align-items: center;
        flex-direction: row;
      }

      ul {
        justify-content: flex-end;
      }

      .nav-item {
        margin-top: 0;
        padding: 0 rem(8px);
        border-color: var(--separator-bg);
        border-width: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .nav-link {
        color: var(--footerLinkTextSecondary);
        font-size: rem(12px);
      }

      @media screen and (max-width: 767px) {
        .container {
          align-items: flex-start;
          flex-direction: column;
        }

        ul {
          justify-content: flex-start;
        }

        .nav-item {
          border: none;
          padding-left: 0;
          justify-content: flex-start;
        }
      }
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
    }

    @media screen and (max-width: 767px) {
      .container {
        flex-direction: column;
        align-items: flex-start;
      }

      ul {
        justify-content: flex-start;
      }

      .nav-item {
        border: none;
        padding-left: 0;
        text-align: left;

        &:last-of-type {
          text-align: left;
        }
      }
    }
  }
  
  .site-tools {
    width: 100%;
  }

  .auxiliary-navigation {
    ul {
      width: auto;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media screen and (max-width: 767px) {
      margin-top: rem(24px); 
      margin-left: 0;
    }
  }

  .nav-item {
    margin-top: rem(24px);
    position: relative;
    border-left: 1px solid #ccc;
    text-align: center;
    padding: 0 rem(44px);

    &:first-of-type {
      text-align: left;
      border-left: none;
      padding-left: 0;
    }
    
    &:last-of-type {
      text-align: right;
      padding-right: 0;
    }

    @media screen and (max-width: 767px) {
      width: 50%;
      margin-right: 0;

      & + .nav-item {
        margin-left: 0;
        margin-bottom: rem(12px);
      }
    }
  }

  .nav-link {
    font-size: rem(12px);
    color: var(--footerLinkText);
    text-transform: uppercase;
  }

  .connect-social {
    margin-top: rem(34px);
    background-image: url("../../../../public/channelsaa/background-pattern.jpg");
    padding: rem(42px) 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 20%;
      background-image: url("../../../../public/channelsaa/border-pattern.png");
      height: rem(24px);
      width: 100%;
      background-repeat: no-repeat;
    }

    .container {
      display: flex;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    h3 {
      color: var(--footerText);
      font-family: var(--tertiaryType);
      text-transform: uppercase;
      font-size: rem(20px);
    } 
    
    ul {
      margin-left: 0;
      margin-top: rem(18px);
    }

    li {
      margin-right: rem(8px);
    }

    @media screen and (max-width: 767px) {
      .container {
        flex-direction: column;
        align-items: flex-start;
        gap: rem(24px);
      }

      ul {
        margin-left: 0;
        margin-top: rem(10px);
      }
    }
  }

  .fcs_ada-wrapper {
    border-top: 2px solid var(--headerAccent);
    padding: 14px 0;
    line-height: 1.6;
    
    
    p {
      font-size: rem(12px);
    }
  }
}

.ada-disclaimer {
  border-top: 1px solid var(--headerAccent);
  padding: rem(20px) 0;

  .fcs-third-party {
    margin-right: rem(30px);
  }
}

span,
a {
  &.fcs-third-party {
    position: relative;
    margin-right: rem(20px);

    &::after {
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../../../../public/auntieannes/third-party-arrow.svg");
      width: rem(32px);
      height: rem(32px);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
