.primary-button,
.secondary-button {
  font-family: $primaryType;
  border: 0;

  &[disabled=""] {
    border: 1px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;

    &:hover {
      background-image: none !important;
    }
  }
}

.button-wrapper {
  a {
    display: inline-block;
  }
}

.primary-link {
  text-decoration: underline;
  font-size: rem(14px);

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

