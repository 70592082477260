.main-header {
  .header-content {
    .logo-wrapper {
      a {
        .logo {
          height: 60px;

          @media screen and (max-width: 767px) {
            height: 40px;
          }
        }
      }
    }

    .header-nav {
      a {
        font-family: $secondaryType;
        font-size: rem(16px);
        text-transform: uppercase;

        @media screen and (max-width: 767px) {
          font-size: rem(14px);
        }
  
        @media screen and (max-width: 374px) {
          font-size: rem(12px);
        }
      }
    }
  }
}

&.aarp-theme, &.success-theme, &.link-account-theme {
  @media screen and (max-width: 767px) {
    .main-header {
      .header-content {
        justify-content: space-between;
      }
     }
  }
}