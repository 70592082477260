.image-title-component {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
  margin-bottom: 64px;

  @media screen and (max-width: 767px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .section-title {
    margin-bottom: 0;
  }

  .image-wrapper {
    margin-left: 36px;

    @media screen and (max-width: 767px) {
      display: none;
    }

    .image {
      max-width: 120px;
    }
  }
}
