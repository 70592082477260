.section-title {
  text-transform: uppercase;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
  color: $headingText;
  font-family: var(--quaternaryType);
  font-size: rem(36px);
}

h1 {
  font-family: var(--quaternaryType);
  text-transform: uppercase;
  font-size: rem(36px);
  color: $headingText;
}

p,
span,
.numbered-list {
  font-family: var(--quinaryType);
}

a {
  color: $linkText;
  font-family: var(--quinaryType);
}

.form-title {
  font-size: rem(32px);
  font-family: var(--quaternaryType);
}

.info-wrapper {
  font-size: rem(20px);
  font-family: var(--quinaryType);
}