@font-face {
	font-family: 'frontage';
	src: url('/assets/carvel/fonts/Frontage-Regular.otf') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'frontage-condensed';
	src: url('/assets/carvel/fonts/frontagecondensed-regular.otf') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'frontage-condensed-bold';
	src: url('/assets/carvel/fonts/frontagecondensed-bold.otf') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'quicksand';
	src: url('/assets/carvel/fonts/Quicksand-Regular.ttf') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'quicksand-semibold';
	src: url('/assets/carvel/fonts/Quicksand-SemiBold.ttf') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'quicksand-bold';
	src: url('/assets/carvel/fonts/Quicksand-Bold.ttf') format('woff');
	font-weight: normal;
	font-style: normal;
}

@import 'shared/variables';

.carvel-theme {
	@import 'shared/typograpgy';
	@import 'shared/header';
	@import 'shared/footer';
  	@import 'components/hero';
}
