.section-title {
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
  color: $headingText;
  font-size: rem(36px);
  font-weight: 700;
  font-family: $primaryType;

  &.left-align {
    text-align: left;
    margin-left: 0;
  }
}

h1 {
  text-transform: uppercase;
  font-size: rem(36px);
  color: $headingText;
  font-weight: 700;
}

p,
span {
  font-weight: 400;
}

a {
  color: $linkText;
  font-weight: 500;
}

&.sweeps-theme {
  h2 {
    font-size: rem(36px);
    font-weight: 700;
    margin: rem(20px) 0 rem(10px);
  }

  h3 {
    font-size: rem(32px);
    font-weight: 700;
  }
  
  .disclaimer-component {
    text-align: center;
  }

  .col img {
    width: 100%;
  }
}

.numbered-list {
  font-family: $primaryType;
}