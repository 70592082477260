.main-footer {
  background-color: var(--footerBg);

  .content-wrapper {
    .footer-text {
      font-family: $primaryType;
      font-size: rem(12px);
      text-transform: uppercase;
    }

    .footer-link-list {
      li {
        a {
          font-family: $secondaryType;
          font-weight: 400;
          font-size: rem(14px);

          @media screen and (max-width: 767px) {
            font-size: rem(12px);
          }
        }
      }
    }
  }
}

.fetchsl-footer {
  background-color: #303539;

  .content-wrapper {
    .footer-text {
      color: #F2EEE2;
    }
  }

  .footer-link-list {
    li {
      a {
        color: #F2EEE2;
      }
    }
  }
}

