h1,
h2 {
  text-transform: uppercase;
  font-weight: 700;
  color: var(--headingText);
}

h1 {
  font-size: rem(58px);
}

h2 {
  font-size: rem(58px);

  @media screen and (max-width: 767px) {
    font-size: rem(26px);
  }
}

p {
  font-family: var(--secondaryType);
  color: var(--bodyText);
  font-size: rem(18px);
  line-height: rem(24px);

  @media screen and (max-width: 767px) {
    font-size: rem(14px);
  }
}
.description {
    margin-top: 1.2em;
    text-align: center;
    line-height: rem(30px);
    font-size: rem(18px);
    b, strong {
      font-family: var(--secondaryTypeBold);
    }
}
.info-wrapper {
  text-align: center;
  margin-bottom: 49px;
  line-height: rem(38px);
  font-size: rem(22px);
  letter-spacing: -0px;
  padding: 0 1em;
  @media screen and (max-width: 767px) {
    font-size: rem(20px);
    line-height: rem(30px);
  }
}
.faint-text {
    color: var(--faintText);
}
.card-component.secondary-style {
  max-width: 600px;
}
.char {
  min-height: 350px;
  min-width: 350px;
  max-width: 475px;
  width: 65%;
  margin: 15px auto;
  display: block;
  &.title {
    min-height: unset;
    max-width: 575px;
    padding-top: 1em;
  }
}
.section-title {
    margin-top: 10px;
    margin-bottom: 15px;
    line-height: rem(55px);
    font-size: rem(40px);
    color: var(--bodyText);
    font-family: var(--primaryType);
    padding: 0 1em;
    letter-spacing: -1px;
    &.small{
      color: var(--headingText);
      font-size: rem(25px);
    }
    @media screen and (max-width: 767px) {
      padding: 0 0.25em;
      font-size: rem(30px);
      line-height: rem(42px);
    }
}
.highlighter {
  background: var(--headingText);
  color: var(--desktop-primary-bg);
  font-family: var(--primaryTypeAlt);
  font-size: 1.875rem;
  margin: 2em auto;
  letter-spacing: -2px;
  min-width: 300px;
  padding: 0.5em 0 0.75em 0;
  text-align: center;
  width: 30%;
    
    a:link, a:-webkit-any-link {
      color:var(--desktop-primary-bg);
      text-decoration: none;
      font-family: var(--primaryTypeAlt);
    }
    
    @media screen and (max-width: 767px) {
      padding: 0.5em 0.5em 0.75em 0.5em;
      width: 35%;
      font-size: 1.5rem;
    }
    @media screen and (max-width: 400px) {
      min-width: 250px;
      font-size: 1.25rem;
    }
    
}
.divider {
  display: block;
  vertical-align: middle;
  margin: 0.5em auto;
  width: 65%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  &.sml {
    width: 55%;
    max-width: 475px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
.woobox-offer, .woobox-offer-wrap, .woobox-offer-wrap > iframe {
  padding: 0 1.5em;
  @media screen and (max-width: 767px) {
    padding: 0 0.25em;
  }
}
.row {
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}
