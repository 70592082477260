.hero-component {
  margin-bottom: 0;
  background-position: center;

  .container {
    flex-direction: row-reverse;

    @media screen and (max-width: 767px) {
      flex-direction: row;
    }
  }

  .content-wrapper {
    background: transparent;

    .title {
      color: var(--headingText);
      text-transform: uppercase;

      span {
        color: var(--headingTextContrast);
        font-weight: bold;
        font-family: var(--secondaryType);
      }
    }
  }
}
