.hero-component {
    .content-wrapper {
        .title {
            font-size: rem(48px);
            font-family: $tertiaryType;
            text-transform: none;
            color: $headingText;

            @media screen and (max-width: 767px) {
                font-size: rem(24px);
            }
        }

        .description {
            margin-top: rem(-32px);
            font-family: var(--tertiaryType);
            color: $headingText;
        }

        p {
            line-height: 1.3;
        }
    }

    .info {
        font-family: $primaryType;
        color: $headingText;
    }
}