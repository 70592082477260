.main-header {
  .header-content {
    .logo-wrapper {
      a {
        .logo {
          max-width: none;
        }
      }
    }
  }
}