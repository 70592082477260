.main-footer {
  .content-wrapper {
    .footer-text {
      font-family: $primaryType;
      font-size: rem(11px);
      text-transform: uppercase;

      a {
        font-family: $primaryType;
      }
    }

    .footer-link-list {
      li {
        a {
          font-weight: 400;
          font-size: rem(13px);
          font-family: $primaryType;

          @media screen and (max-width: 767px) {
            font-size: rem(12px);
          }
        }
      }
    }
  }
}
