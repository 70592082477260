@font-face {
	font-family: "BrooklynSamuelsFive";
	src: url("/assets/schlotzskys/fonts/357C4B_0_0.woff2") format("woff2");
}

@font-face {
	font-family: "BrooklynSamuelsNo3";
	src: url("/assets/schlotzskys/fonts/BrooklynSamuelsThree-Light.otf");
}

@font-face {
	font-family: "Montserrat";
	src: url("/assets/schlotzskys/fonts/montserrat.woff2") format("woff2");
}

@font-face {
	font-family: "Montserrat";
	font-weight: bold;
	src: url("/assets/schlotzskys/fonts/montserrat-bold.woff2") format("woff2");
}

@import "shared/variables";

.schlotzskys-theme {
	@import "shared/utilities";
	@import "shared/typography";
	@import "shared/base";
	@import 'shared/footer';

	@import "shared/inputs";
	@import "shared/buttons";
	@import "shared/header";

	@import "components/hero";
	@import "components/card";
	@import "components/spotlight";
	@import "components/image-title";
}

.csschaun-theme {
	h3 {
		font-family: var(--secondaryType);
		font-weight: bold;
		font-size: rem(32px);
		text-transform: uppercase;
		margin-bottom: rem(10px);
	}

	.main-header {
		.header-content {
			.logo-wrapper {
				a {
					.logo {
						height: 100px;

						@media screen and (max-width: 767px) {
							height: 70px;
						}
					}
				}
			}
		}
	}

	.hero-component {
		.content-wrapper {
			background: transparent;
		}

		.title-image-wrapper {
			margin-bottom: rem(10px);
		}

		p {
			text-transform: uppercase;
			font-weight: bold;
		}
	}

	.main-footer {
		text-align: center;
		padding: rem(70px) 0;

		a {
			text-transform: uppercase;
			font-weight: bold;

			&:hover {
				text-decoration: none;
			}

			&.fcs-third-party {
        &::after {
          content: "";
          background-image: url("../../../public/schlotzskys/third-party.svg");
          background-size: contain;
          background-repeat: no-repeat;
          display: inline-block;
          width: .84em;
          height: .84em;
          margin-left: .6rem;
        }
      }
		}

		a,
		p {
			color: $white;
		}

		.social-navigation {
			margin: rem(30px) 0;
			padding: rem(30px) 0;
			border-top: rem(2px) solid $white;
			border-bottom: rem(2px) solid $white;
		}

		.primary-navigation {
			a {
				text-decoration: underline;
			}
		}

		.secondary-navigation {
			margin-bottom: rem(30px);
		}

		.primary-navigation,
		.social-navigation,
		.secondary-navigation {

			ul {
				display: flex;
				justify-content: center;

				a {
					margin-right: rem(10px);
				}
			}
		}

		.primary-navigation {
			ul {
				@media screen and (max-width: 1440px) {
					flex-flow: column;
				}
			}

			li {
				@media screen and (max-width: 1440px) {
					margin-bottom: rem(10px);
				}
			}
		}
	}
}

.schlotzskys-theme {
	&.aarp-theme {
		h1 {
			font-family: $secondaryType;
		}

		p {
			font-family: $tertiaryType;
		}

		.form-wrapper {
			h3 {
				font-family: $tertiaryType;
				font-weight: 700;
				font-size: rem(22px);
			}

			label, span, a {
				font-family: $tertiaryType;
			}
		}
	}

	&.success-theme {
		.section-subtitle {
			font-family: $tertiaryType;
			font-size: rem(22px);
		}

		.disclaimer-component {
			p {
				font-family: $tertiaryType;
			}
		}

		.content-wrapper {
			p {
				font-family: $tertiaryType;
				font-weight: 700;
			}

			ol {
				font-family: $tertiaryType;
			}
		}
	}

	&.tmobiletuesdayssept10-theme {
		.info-wrapper {
			font-family: $tertiaryType;
			font-size: rem(18px);

			&:first-of-type {
				font-size: rem(22px);
			}
		}

		h3 {
				font-family: $tertiaryType;
				font-weight: 700;
				font-size: rem(22px);
			}

		.form-wrapper {
			label, span, a {
				font-family: $tertiaryType;
			}
		}

		.disclaimer-component {
			margin: 64px 0 0;
			p {
				text-align: left;
				font-family: $tertiaryType;
			}
		}

		.disclaimer-additional {
			p {
				font-family: $tertiaryType;
				font-size: rem(14px);
				color: var(--disclaimerText);;
			}
		}

		ol, a {
			font-family: $tertiaryType;
		}


	}
}