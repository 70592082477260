.main-footer {
  background-color: transparent;

  .nav-wrapper {
    background-color: var(--footerBg);
    padding: rem(40px) 0 rem(40px);

    &.secondary {
      background-color: transparent;

      .container {
        align-items: center;
      }

      .nav-item {
        margin-top: 0;
      }

      .nav-link {
        color: var(--footerLinkTextSecondary);
      }

      @media screen and (max-width: 767px) {
        .container {
          align-items: flex-start;
        }
      }
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
  
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start; 
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: rem(64px);

    @media screen and (max-width: 767px) {
      margin-top: rem(24px);
      margin-left: 0;
    }
  }

  .nav-item {
    margin-right: rem(40px);
    margin-top: rem(24px);

    &:last-of-type {
      margin-right: 0;
    }

    @media screen and (max-width: 767px) {
      width: 50%;
      margin-right: 0;

      & + .nav-item {
        margin-left: 0;
        margin-bottom: rem(12px);
      }
    }
  }

  .nav-link {
    font-size: rem(12px);
    color: var(--footerLinkText);
    font-family: var(--secondaryTypeBold);
    text-transform: uppercase;
  }

  .connect-social {
    margin-top: rem(34px);
    
    h3 {
      color: var(--footerText);
      font-family: var(--tertiaryType);
      text-transform: uppercase;
      font-weight: 700;
    } 

    .container {
      display: flex;
      align-items: center;
    }

    ul {
      margin-left: rem(40px);
    }

    li {
      margin-right: rem(8px);
    }

    @media screen and (max-width: 767px) {
      .container {
        flex-direction: column;
        align-items: flex-start;
      }

      ul {
        margin-left: 0;
        margin-top: rem(10px);
      }
    }
  }

  .fcs_ada-wrapper {
    border-top: 2px solid var(--primary-border);
    padding: 14px 0;
    font-size: rem(12px);
    line-height: 1.6;
  }
}

span.fcs-third-party {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='14' viewBox='0 0 13 14' fill='none'%3E%3Cg clip-path='url(%23clip0_101_387)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.37717 3.29503C5.49683 3.05571 7.16066 2.67679 8.31166 2.04146C10.1379 1.03007 11.286 -0.676488 12.3003 0.332059C12.8387 0.867672 10.5937 5.10984 10.306 6.03007C10.2632 6.16967 11.343 6.3691 9.73616 8.30927C8.95269 9.25514 8.44271 6.48306 8.31166 6.31497C8.08089 6.02437 5.02676 9.85057 3.11508 11.9702C2.343 12.8249 2.30596 13.7053 0.904254 13.1526C-0.543039 12.5828 1.3373 11.5058 2.32876 10.3036C4.27462 7.94175 6.79029 5.47736 6.88716 5.17536C7.00112 4.81924 5.13501 3.76796 5.37717 3.29503Z' fill='%2389113A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_101_387'%3E%3Crect width='12' height='13.2906' fill='white' transform='translate(0.380005 0.0300293)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    width: rem(20px);
    height: rem(20px);
    top: rem(0px);
  }
}

