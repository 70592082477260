& {
  color: $bodyText;
  font-size: rem(16px);
}

h1,
h2,
h3,
h4,
.h1-like {
  font-family: $secondaryType;
  font-weight: 600;
  color: $headingText;

  @media screen and (max-width: 767px) {
    font-size: rem(26px);
  }
}

h2 {
  font-size: rem(40px);
  margin-bottom: rem(24px);
}

h3 {
  font-size: rem(34px);
  margin-bottom: rem(24px);
}

h4 {
  font-size: rem(24px);
  margin-bottom: rem(24px);
}

.h1-like {
  font-size: rem(32px);
}

a {
  text-decoration: none;
  color: $linkText;

  &:hover {
    text-decoration: underline;
  }
}

.section-title {
  text-transform: uppercase;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
}