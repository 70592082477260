.schlotzskys-theme {
    --black:                    #000000;
    --inputFieldsBorder:        #313131;
    --inputFieldsFocus:         #ff0000;
    --linkText:                 #de321b;
    --primaryButtonBg:          #ff6c37;
    --primaryButtonBorder:      #ff6c37;
    --primaryButtonHover:       #40c2cc;
    --primaryButtonHoverText:   #000000;
    --primaryButtonText:        #000000;
    --secondaryButtonBg:        #313131;
    --secondaryButtonBorder:    #313131;
    --secondaryButtonHover:     #313131;
    --secondaryButtonHoverText: #ffffff;
    --secondaryButtonText:      #ffffff;
    --white:                    #ffffff;
    --headerAccent:             #ff6c37;
    --headerLinkText:           #000000;
    --headingText:              #313131;
    --footerBg:                 #313131;
    --footerLinkText:           #ffffff;
    --footerText:               #a8a8a8;
    
    --primaryType:               'Montserrat', sans-serif;
    --secondaryType:             'BrooklynSamuelsFive', sans-serif;
    --tertiaryType:              'BrooklynSamuelsNo3', sans-serif;
}
