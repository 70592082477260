.primary-button,
.secondary-button {
    background-color: $primaryButtonBg;
    border: 2px solid $primaryButtonBorder;
    color: $primaryButtonText;
    cursor: pointer;
    font-family: var(--quaternaryType);
    padding: 12px 10px;
    text-decoration: none;
    font-weight: 400;
    transition: all .2s ease;
    border-radius: 500px;
    font-size: rem(18px);
    min-width: 150px;
    text-align: center;
}

.primary-button:hover,
.primary-button:focus {
    background-color: $primaryButtonHover;
    border: 2px solid $primaryButtonHover;
    color: $primaryButtonHoverText;
}

.secondary-button {
    background: $secondaryButtonBg;
    border: 2px solid $secondaryButtonBorder;
    color: $secondaryButtonText;
}

.secondary-button:hover,
.secondary-button:focus {
    background-color: $secondaryButtonHover;
    border: 2px solid $secondaryButtonHover;
    color: $secondaryButtonHoverText;
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .primary-button,
  .secondary-button {
      padding: 15px 10px 8px;
  }
}