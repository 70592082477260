.section-title {
  text-transform: uppercase;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
  color: $headingText;
  font-family: $secondaryType;
  font-size: rem(36px);
}

h1 {
  font-family: $secondaryType;
  text-transform: uppercase;
  font-size: rem(36px);
  color: $headingText;
}

p,
span {
  font-family: $primaryType;
}

a {
  color: $linkText;
}