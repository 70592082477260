.primary-button,
.secondary-button {
  border: 2px solid $primaryButtonBg;
  background-color: $primaryButtonBg;
  cursor: pointer;
  padding: 10px 40px;
  text-transform: uppercase;
  color: $white;
  font-size: rem(20px);
  font-family: $secondaryType;

  &:hover {
    border: 2px solid $primaryButtonHover;
    background: $primaryButtonHover;
    color: $primaryButtonText;
    text-decoration: none;
  }

  @media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    height: 46px;
    padding: 4px 10px 0;
    line-height: 40px;
  }
}

.secondary-button {
  border: 2px solid $secondaryButtonBorder;
  background: $secondaryButtonBg;

  &:hover {
    background: $secondaryButtonHover;
    border: 2px solid $secondaryButtonBorder;
    color: $secondaryButtonHoverText;
  }
}
