input[type=text],
input[type=email],
input[type=phone],
input[type=password],
select {
  border-radius: 5px;
  font-size: rem(14px);
  padding: 14px;
  font-family: $primaryType;

  @media screen and (max-width: 767px) {
    font-size: rem(16px);
  }
}

