.disclaimer-component {
    max-width: 80%;
    margin: 64px auto 0;
    font-size: rem(14px);
    color: $disclaimerText;

    @media screen and (max-width: 767px) {
        margin-top: 32px;
      }

    p {
        text-align: center;
    }

    & + .disclaimer-component {
        margin-top: 12px;
    }
}
