.row {
    display: flex;
    padding-left: rem(8px);
    padding-right: rem(8px);
    margin-left: rem(-8px);
    margin-right: rem(-8px);

    &.sectioned {
      & > .col {
        &:first-child {
          padding-right: rem(44px);
          border-right: 1px solid var(--black);
        }

        &:last-child {
          padding-left: rem(44px);
        }
      }
    }
    

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;

      &.sectioned {
        & > .col {
          &:first-child {
            padding-right: rem(8px);
            margin-bottom: rem(74px);
          }
  
          &:last-child {
            padding-left: rem(8px);
          }
        }
      }
    }
  
    .col {
      flex-basis: 100%;
      padding-left: rem(8px);
      padding-right: rem(8px);

      &.small {
        flex-basis: 75%;
      }

      @media screen and (max-width: 767px) {
        &.small {
          flex-basis: 100%;
        }
      }
    }

    .row {
        padding-left: 0;
        padding-right: 0;
    }

    &.v-align-end {
      align-items: flex-end;
    }

    &.v-align-center {
      align-items: center;
    }
}
