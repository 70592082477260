.main-header {
  .header-content {
    .logo-wrapper {
      a {
        .logo {
          @media screen and (max-width: 767px) {
            max-width: 220px;
            max-height: 46px;
          }
        }
      }
    }
  }
}

  &.aarp-theme {
    @media screen and (max-width: 767px) {
      .main-header {
        .header-content {
          justify-content: space-between;
        }
       }
    }
  }