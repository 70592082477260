h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
small {
  font-family: $primaryType;
}

a {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

label {
  a {
    display: inherit;
  }
}

.section-title {
  display: block;
  margin-bottom: 32px;
  text-align: center;
  color: $headingText;
  font-family: $secondaryType;
  font-size: rem(38px);

  @media screen and (max-width: 767px) {
    font-size: rem(28px);
  }

  &.small {
    font-size: rem(24px);
  }
}

.section-subtitle {
  font-size: rem(18px);
  line-height: 1.3;
  display: block;
  text-align: center;
  margin-top: -22px;

  @media screen and (max-width: 767px) {
    text-align: left;
  }

  &.secondary-style {
    font-size: rem(16px);
    max-width: 90%;
    margin: 16px auto 48px; 
  }
}

.fetchmca-container {
    .info-wrapper {
      font-size: rem(22px);

      @media screen and (max-width: 768px) {
      font-size: rem(20px);
      }
    }

    label, option, select, li {
      font-family: $primaryType;
    }
  }

  .section-title, h1 {
    font-family: $secondaryType;
  }