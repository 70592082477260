.spotlight-component {
    .description-wrapper {
        .title-image-wrapper {
            .title {
                @media screen and (max-width: 767px) {
                    font-size: rem(24px);
                }
            }
        }
    }
}