.primary-button,
.secondary-button {
    background-color: $primaryButtonBg;
    border: 2px solid $primaryButtonBorder;
    color: $white;
    cursor: pointer;
    font-family: var(--quaternaryType);
    padding: rem(10px) rem(15px);
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 900;
    transition: all .2s ease;
    font-size: rem(14px);
    text-align: center;
    display: flex;
    align-items: center;

    &::after {
        content: "";
        background-image: url("../../../../public/auntieannes/external-icon-light.svg");
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-left: 6px;
    }
}

.primary-button {
    &:hover,
    &:focus {
        background-color: $primaryButtonHover;
        border: 2px solid $primaryButtonHover;
        color: $primaryButtonHoverText;

        &::after {
            background-image: url("../../../../public/auntieannes/external-icon-dark.svg");
        }
    }
}

.secondary-button {
    background: $secondaryButtonBg;
    border: 2px solid $secondaryButtonBorder;
    color: $secondaryButtonText;
}

.secondary-button {
    &:hover,
    &:focus {
        background-color: $secondaryButtonHover;
        border: 2px solid $secondaryButtonHover;
        color: $secondaryButtonHoverText;
    }
}

@media (-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi) {
    .primary-button,
    .secondary-button {
        padding: rem(10px) rem(15px);
    }
}