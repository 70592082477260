.mcalisters-theme,
.csmcacin-theme {
    --inputFieldsBorder:        #1c2529;
    --invalidState:             #e64b38;
    --inputFieldsFocus:         #c0461b;
    --linkText:                 #d7572b;
    --primaryButtonBg:          #d7572b;
    --primaryButtonBorder:      #d7572b;
    --primaryButtonHover:       #e56d43;
    --primaryButtonHoverText:   #ffffff;
    --primaryButtonText:        #ffffff;
    --secondaryButtonBg:        #4e6e60;
    --secondaryButtonBorder:    #4e6e60;
    --secondaryButtonHover:     #6f9987;
    --secondaryButtonHoverText: #ffffff;
    --secondaryButtonText:      #ffffff;
    --headerAccent:             #6fa287;
    --headerLinkText:           #494949;
    --footerBg:                 #fafafa;
    --footerLinkText:           #4e6e60;
    --footerText:               #1c2529;
    --headingText:              #1c2529;
    --bodyText:                 #1c2529;
    --disclaimerText:           #74747B;
    --mobile-primary-bg:        #fafafa;
    --desktop-primary-bg:       #fafafa;
    --secondary-bg:             #ffffff;

    --primaryType:             'Din', sans-serif;
    --secondaryType:           'Bourton', sans-serif;
    --tertiaryType:            'Aromatica', sans-serif;
}

