.focus-outlines {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  select {
    &:focus {
      outline: 2px solid $inputFieldsFocus;
      outline-offset: 3px;
    }
  }

  input[type="checkbox"] {
    &:focus {
      outline: 2px solid $inputFieldsFocus;
      outline-offset: 1px;
    }
  }

  a,
  button {
    &:focus {
        outline: 2px solid $linkText !important;
        outline-offset: 3px;
    }
  }
}
