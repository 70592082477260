.jamba-theme {
    --inputFieldsBorder:        #2b3936;
    --invalidState:             #e64b38;
    --inputFieldsFocus:         #c0461b;
    --linkText:                 #036d58;
    --primaryButtonBg:          #036d58;
    --primaryButtonBorder:      #036d58;
    --primaryButtonHover:       #fac53c;
    --primaryButtonHoverText:   #2b3936;
    --primaryButtonText:        #ffffff;
    --secondaryButtonBg:        #fac53c;
    --secondaryButtonBorder:    #fac53c;
    --secondaryButtonHover:     #036d58;
    --secondaryButtonHoverText: #ffffff;
    --secondaryButtonText:      #2b3936;
    --headerAccent:             #036d58;
    --headerLinkText:           #494949;
    --footerBg:                 #f6f9f9;
    --footerLinkText:           #2b3936;
    --footerText:               #2b3936;
    --footerTextHover:          #036d58;
    --headingText:              #2b3936;
    --bodyText:                 #2b3936;
    --disclaimerText:           #74747B;
    --mobile-primary-bg:        #f6f9f9;
    --desktop-primary-bg:       #f6f9f9;
    
    --primaryType:              'Filson Soft W03', Arial, Helvetica, sans-serif;
}