.hero-component {
    .content-wrapper {
        .title {
            @media screen and (max-width: 767px) {
                font-size: rem(24px);
            }
        }

        p {
            line-height: 1.3;
        }
    }

    &.dr-pepper-campaign {
        @media screen and (max-width: 1600px) and (min-width: 1280px) {
            background-size: contain;
            background-repeat: no-repeat;
            min-height: 400px;
        }

        @media screen and (max-width: 1280px) and (min-width: 960px) {
            min-height: 320px;
        }

        @media screen and (max-width: 960px) and (min-width: 767px) {
            min-height: 200px;
        }
    }
}