.main-header {
  .header-content {
    .logo-wrapper {
      a {
        .logo {
          max-width: 150px;
        }
      }
    }
  }
}

&.sweeps-theme,
&.fuelrewards-theme,
&.fuelrewardslto-theme {
  .main-header {
    .header-content {
      .logo-wrapper {
        a {
          .logo {
            max-width: 100%;
          }
        }
      }
    }
  }
}

&.aarp-theme {
  @media screen and (max-width: 767px) {
    .main-header {
      .header-content {
        justify-content: space-between;
      }
    }
  }
}

&.tmobiletuesdaysoct8-theme {
  .main-header {
    .header-content {
      .logo-wrapper {
        a {
          .logo {
            max-width: 220px;
          }
        }
      }
    }
  }
}
