.main-header {
  .header-content {
    .logo-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        height: auto;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }

    .benefits-wrapper {
      max-width: 100px;

      .benefits-logo {
        width: 100%;
        height: 100%;
      }
    }
  }
}

&.fetch-theme {
.main-header {
  .header-content {
    .logo-wrapper {
      a {
        .logo {
          max-width: 220px;
          height: auto;
        }
      }
    }
  }
}
}