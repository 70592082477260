.main-footer {
  .content-wrapper {
    .footer-text {
      font-family: var(--quaternaryType);
      color: var(--midnight-blue);
      font-size: rem(14px);
    }

    .footer-link-list {
      li {
        a {
          font-weight: 400;
          font-size: rem(14px);
          font-family: var(--quaternaryType);
          color: var(--midnight-blue);
          text-transform: none;

          @media screen and (max-width: 767px) {
            font-size: rem(12px);
          }
        }
      }
    }
  }
}
