.hero-component {
    background-position: center;

    .content-wrapper {
        .title {
            font-size: rem(48px);
            font-family: $tertiaryType;
            text-transform: uppercase;
            color: $headingText;

            @media screen and (max-width: 767px) {
                font-size: rem(24px);
            }
        }

        p {
            line-height: 1.3;
        }
    }

    .info {
        font-family: $tertiaryType;
        text-transform: uppercase;
        color: $headingText;
    }
}