@font-face {
    font-family: "Bourton";
    src: url("/assets/mcalisters/fonts/Bourton.ttf") format("truetype");
}

@font-face {
    font-family: "Din";
    src: url("/assets/mcalisters/fonts/Din-regular.woff2") format('woff2');
}

@font-face {
    font-family: "Aromatica";
    src: url("/assets/mcalisters/fonts/Aromatica.ttf") format('ttf');
}

@import 'shared/variables';

.mcalisters-theme,
.csmcacin-theme {
    @import 'shared/typography';
    @import 'shared/inputs';
    @import "shared/header";
    @import "shared/buttons";
	@import "shared/footer";
    @import "components/hero";
    @import "components/spotlight";
    @import "components/image-title";
}

.csmcacin-theme {
    .hero-component {
        .title-image-wrapper {
            margin-bottom: rem(10px);
            font-weight: 700;
        }

        .description {
            font-family: var(--secondaryType);
        }
    }
}

.mcalisters-theme {
    &.success-theme {
        .section-subtitle {
            margin-top: rem(20px);
            font-size: rem(22px);
        }

        ol {
            font-family: $primaryType;
        }
    }

    &.aarp-theme {
        .landing-process, .landing-completed-message, .form-group {
            font-family: $primaryType;
        }

        h1 {
            font-size: rem(40px)
        }

        h3 {
            font-size: rem(30px)
        }

    }

    &.tmobiletuesdayssept24-theme {
		.info-wrapper {
			font-family: $primaryType;
			font-size: rem(18px);

			&:first-of-type {
				font-size: rem(22px);
			}
		}

		h3 {
				font-family: $primaryType;
				font-weight: 700;
				font-size: rem(22px);
			}

		.form-wrapper {
			label, span, a {
				font-family: $primaryType;
			}
		}

		.disclaimer-component {
			margin: 64px 0 0;
			p {
				text-align: left;
				font-family: $tertiaryType;
			}
		}

		.disclaimer-additional {
			p {
				font-family: $tertiaryType;
				font-size: rem(14px);
				color: var(--disclaimerText);;
			}
		}

		ol {
			font-family: $tertiaryType;
		}

	}
}
