.cinnabon-theme,
.cscinmca-theme,
.cscinmoe-theme {
    --inputFieldsBorder:        #2b3936;
    --invalidState:             #e64b38;
    --inputFieldsFocus:         #c0461b;
    --linkText:                 #009fa5;
    --primaryButtonBg:          #071d49;
    --primaryButtonBorder:      #071d49;
    --primaryButtonHover:       #071d49;
    --primaryButtonHoverText:   #64ccc9;
    --primaryButtonText:        #ffffff;
    --secondaryButtonBg:        #523428;
    --secondaryButtonBorder:    #523428;
    --secondaryButtonHover:     #523428;
    --secondaryButtonHoverText: #64ccc9;
    --secondaryButtonText:      #ffffff;
    --headerAccent:             #0f1d49;
    --headerLinkText:           #494949;
    --footerBg:                 #ffffff;
    --footerLinkText:           #523428;
    --footerText:               #523428;
    --footerTextHover:          #036d58;
    --headingText:              #071d49;
    --bodyText:                 #2b3936;
    --disclaimerText:           #74747B;
    --mobile-primary-bg:        #ffffff;
    --desktop-primary-bg:       #efeff0;
    --delicious-pink:           #DA1884;
    --midnight-blue:            #071D49;

    --primaryType:             'gotham-web', Helvetica, sans-serif;
    --secondaryType:           'gotham-bold-web', Helvetica, sans-serif;
    --tertiaryType:            'thirstyrough-web', Helvetica, sans-serif;
    --quaternaryType:          'vc-henrietta', Helvetica, sans-serif;
    --quinaryType:             'urbane-rounded', Helvetica, sans-serif;
}