.primary-button,
.secondary-button {
    border: 2px solid $primaryButtonBorder;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-family: $secondaryType;
    font-size: rem(18px);
    padding: 7px 10px 13px;    
    min-width: 200px;
    -webkit-appearance: none;
    transition: background-color .5s ease;
    cursor: pointer;
    text-align: center;

  &:hover {
    text-decoration: none;
  }

  @media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    height: 46px;
    padding: 0 10px 0;
    line-height: 40px;
  }
}

.primary-button {
    background-color: $primaryButtonBg;
    border: 2px solid $primaryButtonBorder;

    &:hover {
      border: 2px solid $primaryButtonBorder;
      background-color: $primaryButtonHover;
    }
}

.secondary-button {
  border: 2px solid $secondaryButtonBorder;
  background: $secondaryButtonBg;

  &:hover {
    border: 2px solid $secondaryButtonBorder;
    background-color: $secondaryButtonHover;
  }
}
