.hero-component {
  // change this so the text won't stay on top of the background's main content (if needed)
  background-position-x: 50%;

  .content-wrapper {
    background: transparent;

    .title {
      @media screen and (max-width: 767px) {
        color: var(--headingTextSecondary);
      }
    }
  }
}