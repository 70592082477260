input[type="text"],
input[type="email"],
input[type="password"],
select {
  border: 1px solid $inputFieldsBorder;
  box-sizing: border-box;
  margin-top: 6px;
  outline: none;
  padding: 14px;
  width: 100%;
  font-family: var(--quinaryType);
  border-radius: 0;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }

  .show-errors &.invalid {
    border: 1px solid $invalidState;
  }
}

select {
  background: $white;
    
  @media screen and (max-width: 767px) {
    height: 46px;
  }
}

input[type="checkbox"] {
  margin-right: rem(6px);

  &:focus {
    outline: none;
  }
}

label {
  font-family: var(--quinaryType);

}

.birthdate {
  display: inline;
  margin: 10px 0 10px;

  & + .error-label {
    margin-top: -6px;
    margin-bottom: 12px;
  }
}

.agree-submit {
  margin-top: 20px;
}

.form-group {
  margin: 10px 0 10px;

  select ~ select {
    margin-left: 5px;
  }

  a {
    margin-top: 10px;
    display: inline-block;
    font-size: rem(16px);

    @media screen and (max-width: 767px) {
      font-size: rem(14px);
    }
  }
}

.error-wrapper {
  .error {
    display: block;
    color: $invalidState;
    margin-bottom: 4px;
  }
}

.error-label {
  color: $invalidState;
  display: none;
  margin-top: 4px;

  .show-errors & {
    display: block;
  }
}
