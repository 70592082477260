.card-component {
    .content-wrapper {
        .title {
            text-transform: uppercase;
            font-family: $secondaryType;
        }

        .description {
            font-family: $primaryType;
            font-weight: normal;
            font-size: rem(18px);
            line-height: 1.3;
        }
    }
}