input[type="text"],
input[type="email"],
input[type="phone"],
input[type="password"],
select {
  font-size: rem(13px);
  padding: 14px;
  
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

label {
  font-size: rem(16px);
}
