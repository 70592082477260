.main-footer {
  padding-top: rem(80px);
  background-color: var(--footerBg);
  position: relative;

  .connect-social {
    position: absolute;
    top: rem(-64px);
    width: 100%;

    h3 {
      font-family: var(--tertiaryType);
      color: var(--footerLinkTextSecondary);
      font-size: rem(24px);
    }

    ul {
      display: flex;
      margin-top: rem(14px);

      li {
        margin-right: rem(24px);
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding-top: rem(40px);
  }

  .auxiliary-navigation {
    padding-bottom: rem(24px);
    margin-bottom: rem(20px);
    border-bottom: 2px solid rgba(7,29,73,0.5);

    p {
      font-size: rem(14px);
      font-weight: 700;
      margin-top: rem(10px);
      color: var(--midnight-blue);
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      font-size: rem(20px);

      @media screen and (max-width: 767px) {
        display: block;
        column-count: 2;
        font-size: rem(16px);
      }
    }

    .nav-item {
      margin-right: rem(76px);
      margin-bottom: rem(14px);
    }

    .nav-link {
      color: var(--footerLinkText);
      font-family: var(--quaternaryType);
      color: var(--midnight-blue);
      text-decoration: none;
      transition: .2s;
      position: relative;

      &:hover {
        text-decoration: underline;
      }

      // &::after {
      //   content: "";
      //   background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 32 32' style='enable-background:new 0 0 32 32;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23009fa5;%7D%0A%3C/style%3E%3Cpath class='st0' d='M26.6,19.8c3.4-1.9,3-5.9-0.2-6.8C19.6,11.1,13.7,7.5,8.7,2.5C6.4,0.1,1.7,2.6,3,5.9C5.6,12.7,5.6,20,3.6,27 c-1,3.6,3.3,5.1,5.7,3.2c4.8-3.8,9.6-6.6,15.1-9.2L26.6,19.8L26.6,19.8z'/%3E%3C/svg%3E");
      //   background-repeat: no-repeat;
      //   position: absolute;
      //   width: rem(16px);
      //   height: rem(16px);
      //   transform: translateY(-50%);
      //   top: 50%;
      //   margin-left: .2rem;
      // }

      // &.fcs-third-party {
      //   &::after {
      //     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31.81' height='36.031' viewBox='0 0 31.81 36.031'%3E%3Cmetadata%3E%3C%3Fxpacket begin='﻿' id='W5M0MpCehiHzreSzNTczkc9d'%3F%3E%3Cx:xmpmeta xmlns:x='adobe:ns:meta/' x:xmptk='Adobe XMP Core 5.6-c142 79.160924, 2017/07/13-01:06:39 '%3E%3Crdf:RDF xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23'%3E%3Crdf:Description rdf:about=''/%3E%3C/rdf:RDF%3E%3C/x:xmpmeta%3E%3C%3Fxpacket end='w'%3F%3E%3C/metadata%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23523428; fill-rule: evenodd; %7D %3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M1219,360c-0.15-2.677,8.12-4,11-4s4.34,2.019,3,4c-2.42,3.589-3.92,7.87-5,8-2.93.354-.94-3-2-3s-7.6,5.434-9,7c-12.39,13.839-11.22,19.357-14,20s-1.14-5.686,5.42-15.339A59.763,59.763,0,0,1,1223,362S1219.15,362.677,1219,360Z' transform='translate(-1201.69 -356)'/%3E%3C/svg%3E");
      //     width: rem(30px);
      //     height: rem(30px);
      //     transform: scale(.5);
      //     top: rem(-2px);
      //     right: rem(-24px);
      //   }
      // }
    }
  }

  .copyright {
    font-size: rem(14px);
    font-weight: 700;
    margin-bottom: rem(20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--midnight-blue);

    @media screen and (max-width: 1280px) {
      flex-flow: column;
    }

    ul {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 960px) {
        flex-flow: column;
        text-align: center;
      }
    }

    li {
      &:not(:last-of-type) {
        &::after {
          content: "|";
          display: inline-block;
          margin: 0 rem(10px);

          @media screen and (max-width: 960px) {
            content: "";
          }
        }
      }
    }

    .nav-link {
      color: var(--midnight-blue);
      font-weight: 700;
      position: relative;
      transition: .2s;
      display: inline-block;
      line-height: 1.6;

      &:hover {
        color: var(--headingTextSecondary);
      }
    }
  }
}
