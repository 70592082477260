.hero-component {
  //min-height: rem(600px);
  background-color: #b1e4e3;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: unset;
  min-height: 393px;
  
  @media screen and (max-width: 900px) {
    padding-top: 285px;
    margin-bottom: 0;
    background-size: cover;
  }
  @media screen and (max-width: 848px) {
    padding-top: 285px;
    margin-bottom: 0;
    min-height: 323px;
    background-size: contain;
  }
  @media screen and (max-width: 767px) {
    padding-top: 175px;
    margin-bottom: 0;
    min-height: 323px;
  }
  @media screen and (max-width: 650px) {
    margin-bottom: 0;
    padding-top: 95px;
    min-height: 150px !important;
    background-size: contain !important;
  }

  .content-wrapper {
    padding-left: 0;
    background: transparent;
  }

  .link-group {
    display: none;
  }

  @media screen and (max-width: 767px) {
    min-height: 0;
    background-size: cover;
  }
}
