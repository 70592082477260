.card-component {
  max-width: rem(420px);
  padding: rem(10px) 0;

  .content-wrapper {
    .title {
      text-transform: uppercase;
      margin-bottom: rem(14px);

      & + .image-wrapper {
        // images are not the same size
        // use 90px as offset
        min-height: rem(90px);
      }
    }

    .description {
      text-transform: uppercase;
      font-size: rem(14px);
    }
  }
}
