.carvel-theme {
    --inputFieldsBorder:        #1c2529;
    --invalidState:             #e64b38;
    --inputFieldsFocus:         #c0461b;
    --linkText:                 #d7572b;
    --primaryButtonBg:          #071D49;
    --primaryButtonBorder:      #071D49;
    --primaryButtonHover:       #030f29;
    --primaryButtonHoverText:   #ffffff;
    --primaryButtonText:        #ffffff;
    --secondaryButtonBg:        #4e6e60;
    --secondaryButtonBorder:    #4e6e60;
    --secondaryButtonHover:     #6f9987;
    --secondaryButtonHoverText: #ffffff;
    --secondaryButtonText:      #ffffff;
    --headerAccent:             #6fa287;
    --headerLinkText:           #494949;
    --footerBg:                 #000000;
    --footerBgSecondary:        #E8F4FD;
    --footerLinkText:           #ffffff;
    --footerLinkTextSecondary:  #89113A;
    --footerText:               #6C7138;
    --headingText:              #EE2737;
    --headingTextSecondary:     #071D49;
    --bodyText:                 #000000;
    --disclaimerText:           #74747B;
    --mobile-primary-bg:        #fafafa;
    --desktop-primary-bg:       #fafafa;
    --primary-border:           #E8F4FD;
    --faintText:                #74747B;

    --primaryType:              'frontage-condensed-bold', sans-serif;
    --primaryTypeAlt:            'frontage-condensed', sans-serif;
    --secondaryType:            'quicksand', sans-serif;
    --secondaryTypeSemibold:    'quicksand-semibold', sans-serif;
    --secondaryTypeBold:        'quicksand-bold', sans-serif;
    --tertiaryType:              Arial, Helvetica, sans-serif;
}