.main-footer {
    .content-wrapper {
      .footer-text {
        font-size: rem(12px);
      }
  
      .footer-link-list {
        li {
          a {
            font-size: rem(12px);
            text-transform: lowercase;
        }
      }
    }
  }
}