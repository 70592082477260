* {
  box-sizing: border-box;
}

html {
  position: relative;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .main-content {
    flex: 1 1 0;
    margin-top: 90px;
    padding-bottom: 60px;

    @media screen and (max-width: 767px) {
      margin-top: 100px;
    }
  }
}

.container {
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 15px;
  width: 100%;

  &.medium {
    max-width: 930px;
  }
}

.success-page-content-wrapper-top {
  display: flex;
  flex-direction: column;
}

.DEV_MENU {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 2px solid $headerAccent;
  border-right: 0;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 8px 0 0 8px;

  a {
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: bold;
    font-size: rem(14px);
    color: $linkText;

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }

  h2 {
    font-size: rem(14px);
    margin-top: 0;
    text-transform: uppercase;
  }
}
