.social-component {
  padding: rem(34px) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .app-link-component {
    margin-top: 0;
  }
}