.hero-component {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  background-position: right;
  background-size: cover;
  min-height: 480px;

  @media screen and (max-width: 767px) {
    min-height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top !important;
    padding-top: 180px;

    &:not(.mobile) {
      display: none;
      min-height: 0;
      background-image: none !important;
    }
  }

  @media screen and (max-width: 575px) {
    min-height: 100px;
    padding-top: 120px;
  }

  @media screen and (max-width: 414px) {
    // custom breakpoint for hero image on mobile
    padding-top: 80px;
  }

  .container {
    display: flex;
  }

  .content-wrapper {
    background: #ffffff;
    max-width: 800px;
    padding: 32px 64px 32px 32px;
    margin: 32px 0;

    @media screen and (max-width: 991px) {
      max-width: 580px;
    }

    @media screen and (max-width: 767px) {
      margin: 0;
      max-width: none;
      padding: 32px 16px 0;

      @media screen and (max-width: 374px) {
        padding: 32px 0 0;
      }
    }

    .title-image-wrapper {
      margin-bottom: 32px;
      align-items: center;

      .image {
        display: none;

        @media screen and (max-width: 767px) {
          display: block;
          margin-left: 32px;
          max-height: 70px;
          max-width: 120px;
        }

        @media screen and (max-width: 359px) {
          max-width: 80px;
        }
      }

      @media screen and (max-width: 767px) {
        display: flex;
      }
    }

    .title {
      margin: 0;
      font-size: rem(52px);
      line-height: 1.1;

      @media screen and (max-width: 767px) {
        font-size: rem(38px);
      }

      @media screen and (max-width: 359px) {
        font-size: rem(32px);
      }
    }

    p {
      margin-bottom: 32px;
      font-size: rem(18px);

      @media screen and (max-width: 767px) {
        max-width: 90%;
      }
    }

    .link-group {
      display: flex;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }

      .link-wrapper {
        margin-right: 64px;

        &:last-child {
          margin-right: 0;
        }

        @media screen and (max-width: 767px) {
          margin: 0 0 24px;
          text-align: center;
        }
      }

      a {
        display: inline-block;
        min-width: 220px;
        text-align: center;
      }
    }
  }

  .image-wrapper {
    display: flex;
    align-self: flex-end;
    margin-bottom: 32px;
    margin-left: 64px;

    @media screen and (max-width: 991px) {
      margin-left: 32px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    .image {
      max-width: 140px;

      @media screen and (max-width: 991px) {
        max-width: 120px;
      }
    }
  }

  .info {
    display: block;
    margin-bottom: 10px;
  }
}

.carvel-theme .hero-component {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  background-position: center, left !important;
  background-size: cover, contain;
  min-height: 550px !important;

  @media screen and (max-width: 767px) {
    min-height: 370px !important;
    background-size: contain, cover;
    background-repeat: no-repeat;
    background-position: center !important;
    padding-top: 180px;

    &:not(.mobile) {
      min-height: 0;
      background-image: none !important;
    }
  }

  @media screen and (max-width: 653px) {
    min-height: 100px;
    padding-top: 210px !important;
    background-position: center, left !important;
  }

  @media screen and (max-width: 414px) {
    // custom breakpoint for hero image on mobile
    padding-top: 80px;
    background-position: center, -800% !important;
  }

  .container {
    display: flex;
  }

  .content-wrapper {
    background: #ffffff;
    max-width: 800px;
    padding: 32px 64px 32px 32px;
    margin: 32px 0;

    @media screen and (max-width: 991px) {
      max-width: 580px;
    }

    @media screen and (max-width: 767px) {
      margin: 0;
      max-width: none;
      padding: 32px 16px 0;

      @media screen and (max-width: 374px) {
        padding: 32px 0 0;
      }
    }

    .title-image-wrapper {
      margin-bottom: 32px;
      align-items: center;

      .image {
        display: none;

        @media screen and (max-width: 767px) {
          display: block;
          margin-left: 32px;
          max-height: 70px;
          max-width: 120px;
        }

        @media screen and (max-width: 359px) {
          max-width: 80px;
        }
      }

      @media screen and (max-width: 767px) {
        display: flex;
      }
    }

    .title {
      margin: 0;
      font-size: rem(52px);
      line-height: 1.1;

      @media screen and (max-width: 767px) {
        font-size: rem(38px);
      }

      @media screen and (max-width: 359px) {
        font-size: rem(32px);
      }
    }

    p {
      margin-bottom: 32px;
      font-size: rem(18px);

      @media screen and (max-width: 767px) {
        max-width: 90%;
      }
    }

    .link-group {
      display: flex;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }

      .link-wrapper {
        margin-right: 64px;

        &:last-child {
          margin-right: 0;
        }

        @media screen and (max-width: 767px) {
          margin: 0 0 24px;
          text-align: center;
        }
      }

      a {
        display: inline-block;
        min-width: 220px;
        text-align: center;
      }
    }
  }

  .image-wrapper {
    display: flex;
    align-self: flex-end;
    margin-bottom: 32px;
    margin-left: 64px;

    @media screen and (max-width: 991px) {
      margin-left: 32px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    .image {
      max-width: 140px;

      @media screen and (max-width: 991px) {
        max-width: 120px;
      }
    }
  }

  .info {
    display: block;
    margin-bottom: 10px;
  }
}
