.main-header {
  min-height: 90px;
  width: 100%;
  border-bottom: 2px solid $headerAccent;
  box-shadow: 0 2px 5px 0 rgba(153,175,172,.6);
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: fixed;
  background: $headerBg;
  z-index: 1;
    
  .header-content {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    
    @media screen and (max-width: 767px) {
      align-items: flex-start;
    }

    .logo-wrapper {
      a {
        display: block;

        .logo {
          height: 70px;
        }
      }
    }

    .header-nav {
      font-size: rem(18px);
      list-style-type: none;
      display: flex;
      max-width: 60%;
      width: 100%;
      justify-content: space-between;
  
      @media screen and (max-width: 767px) {
        max-width: 100%;
        margin-top: 10px;
      } 
  
      li {
        margin-right: rem(8px);

        &:last-child {
          margin-right: 0;
        }
      }
  
      a {
        font-size: rem(20px);
        font-family: $secondaryType;
        color: $headerLinkText;
        text-transform: uppercase;
  
        @media screen and (max-width: 767px) {
          font-size: rem(18px);
        }
  
        @media screen and (max-width: 359px) {
          font-size: rem(16px);
        }
      }
    }

    .benefits-wrapper {
      max-width: 100px;

      .benefits-logo {
        width: 100%;
        height: 100%;
      }
    }
  }

  .benefits-wrapper {
    max-width: 100px;

    .benefits-logo {
      width: 100%;
      height: 100%;
    }
  }
}

.aarp-theme, .success-theme, .link-account-theme {
  .main-header {
    .header-content {
      @media screen and (max-width: 767px) {
        justify-content: space-between;
      }
    }
  }
}