.main-footer {
  .content-wrapper {
    .footer-text {
      font-family: $primaryType;
      font-size: rem(11px);
    }

    .footer-link-list {
      li {
        a {
          font-weight: 500;
          font-size: rem(12px);
          font-family: $primaryType;
          text-transform: lowercase;
          text-decoration: underline;

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }
    }
  }
}
