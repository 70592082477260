& {
  color: $bodyText;
  font-size: rem(13px);
}

h1 {
  font-family: $secondaryType;
  font-size: rem(36px);
  color: $headingText;

  @media screen and (max-width: 767px) {
    font-size: rem(26px);
  }
}

a {
  text-decoration: none;
  color: $linkText;
  font-family: $primaryType;

  &:hover {
    text-decoration: underline;
  }
}

label {
  a {
    display: inherit;
  }
}