.spotlight-component {
    .description-wrapper {
        p {
            font-size: rem(16px);
            font-family: $primaryType;
        }

        .title-image-wrapper {
            .title {
                font-family: $secondaryType;
                text-transform: uppercase;
                color: $headingText;
            }
        }
    }
}