.app-link-component {
  margin: 32px 0 48px;
  text-align: center;

  a {
    display: inline-block;
    margin-right: 24px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      @media screen and (max-width: 359px) {
        max-width: 120px;
      }
    }
  }
}
