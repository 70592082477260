.main-header {
  height:100px;
  .header-content {
    .logo-wrapper {
      a {
        .logo {
          height: 50px;
          padding: 2.5px 0;
        }
        
      }
      @media screen and (max-width: 767px) {
        margin: 0 auto;
        text-align: center;
        width: 100%;
      }
    }
  }
}
