@font-face {
  font-family: "TradeGothic";
  src: url("/assets/moes/fonts/TradeGothicLTStd-BdCn20.woff2") format("woff2");
}

@font-face {
  font-family: "TradeGothicLight";
  src: url("/assets/moes/fonts/TradeGothicLTStd-Light.otf");
}


@font-face {
  font-family: "GothamBook";
  src: url("/assets/moes/fonts/gotham_book-webfont.woff2") format("woff2");
}

@import 'shared/variables';

.moes-theme,
.csmoecin-theme {
  @import 'shared/typography';
  @import 'shared/header';
  @import 'shared/footer';

  @import 'shared/inputs';
  @import 'shared/buttons';
}

.csmoecin-theme {
  .hero-component {
    .content-wrapper {
      margin: 32px auto 32px 0;

      .title-image-wrapper {
        margin-bottom: 0;

        .title {
          color: var(--secondaryButtonText);
        }
      }

      .description {
        color: var(--secondaryButtonText);
      }
    }

    .description {
      font-family: var(--secondaryType);
    }
  }

  .section-title {
    margin-top: rem(30px);
  }

  .main-footer {
    .footer-link-list {
      width: auto;
    }
  }
}

.moes-theme {
  &.aarp-theme {
    .landing-head {
      h1 {
        font-family: var(--secondaryType);
      }

      p {
        font-family: var(--tertiaryType);
      }
    }

    .form-wrapper {
      h3 {
        font-family: var(--secondaryType);
        font-size: rem(24px);
      }

      label {
        font-size: rem(16px);
      }

      .form-group {
        font-family: var(--tertiaryType);

        a {
          font-family: var(--tertiaryType);
        }
      }
    }
  }

  &.success-theme {
    .section-subtitle, .card-component, a, ol {
      font-family: var(--tertiaryType);
    }
  }

  &.link-account-theme {
    .form-group, select, .note-wrapper, span {
      font-family: var(--tertiaryType);
    }
  }

  &.tmobiletuesdaysnov12-theme {
		h1, h2 {
			font-family: var(--tertiaryType);
      font-weight: 700;
		}

		.info-wrapper {
			font-family: var(--tertiaryType);
			font-size: rem(18px);
		}
	
		h3 {
				font-family: var(--tertiaryType);
				font-weight: 700;
				font-size: rem(22px);
			}
	
		.form-wrapper {
			label, span, a {
				font-family: var(--tertiaryType);
			}
		}
	
		.disclaimer-component {
			margin: 64px 0 0;
			max-width: 100%;
      
			p {
				text-align: left;
				font-family: var(--tertiaryType);
			}
		}
	
		ol {
			font-family: var(--tertiaryType);
			font-size: 1.125rem;
		}
	}
}

