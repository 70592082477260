.spotlight-component {
  display: flex;
  margin-bottom: 32px;

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }

  .description-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 32px 64px;
    flex-basis: 45%;

    .title-image-wrapper {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      .title {
        margin-top: 0;
        margin-bottom: 26px;
        font-size: rem(42px);
  
        @media screen and (max-width: 767px) {
          margin-bottom: 0;
          font-size: rem(32px);
        }
      }

      .image {
        display: none;
        padding-right: 32px;

        @media screen and (max-width: 767px) {
          display: block;
          max-height: 60px;
          padding-right: 16px;
          padding-left: 16px;
        }

        @media screen and (max-width: 359px) {
        }
      }

      @media screen and (max-width: 767px) {
        display: flex;
      }
    }


    @media screen and (max-width: 767px) {
      padding: 0;
    }

    p {
      font-size: rem(22px);
      line-height: 1.3;
      margin-bottom: 10px;

      @media screen and (max-width: 767px) {
        font-size: rem(18px);
      }
    }

    .link-wrapper {
      a {
        display: inline-block;
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .image-wrapper {
    align-self: center;
    flex-basis: 55%;

    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
      display: none;
    }

    .image {
      width: 100%;
    }
  }

  &.secondary-styling {
    @media screen and (max-width: 767px) {
      justify-content: center;
    }
    .description-wrapper {
      justify-content: space-around;
      align-items: flex-start;

      p {
        font-size: rem(16px);

        @media screen and (max-width: 767px) {
          max-width: 360px;
          margin-bottom: 32px;
        }

        @media screen and (max-width: 375px) {
          margin-bottom: 16px;
        }
      }

      .title-image-wrapper {
        padding-right: 0;

        .title {
          margin-bottom: 10px;

          @media screen and (max-width: 767px) {
            margin-bottom: 0;
          }
        }
      } 

      @media screen and (max-width: 991px) {
        padding: 32px 24px;
        flex-basis: 50%;
      }

      @media screen and (max-width: 767px) {
        align-items: center;
      }

      @media screen and (max-width: 375px) {
        padding: 32px 16px;
      }

      .link-wrapper {
        margin-top: 16px;
        @media screen and (max-width: 375px) {
          display: flex;
          justify-content: space-between;

          a {
            img {
              max-width: 120px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      text-align: center;
    }

    .image-wrapper {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}
