h1,
.section-title {
  color: var(--delicious-pink);
  font-family: var(--tertiaryType);
  text-transform: none;
}

h3 {
  &.section-title {
    font-size: rem(24px);
  }

  &.form-title {
    font-size: rem(24px);
    font-family: var(--tertiaryType);
  }
}

p, ol, li, label, select, span {
  font-family: var(--primaryType);
  color: var(--midnight-blue);
}

.info-wrapper {
  font-size: rem(20px);
}

.cin-rewards-text {
  font-size: 1.75em;
  font-family: var(--primaryType);
  margin-bottom: 24.12px;
}