.moes-theme,
.csmoecin-theme {
    --inputFieldsBorder:        #243746;
    --invalidState:             #e64b38;
    --inputFieldsFocus:         #E64B38;
    --linkText:                 #E64B38;
    --primaryButtonBg:          #e64b38;
    --primaryButtonBorder:      #e64b38;
    --primaryButtonHover:       #ffffff;
    --primaryButtonHoverText:   #e64b38;
    --primaryButtonText:        #ffffff;
    --secondaryButtonBg:        #3a3a3c;
    --secondaryButtonBorder:    #3a3a3c;
    --secondaryButtonHover:     #ffffff;
    --secondaryButtonHoverText: #3a3a3c;
    --secondaryButtonText:      #ffffff;
    --headerAccent:             #1DCAD3;
    --headerLinkText:           #494949;
    --footerBg:                 #A53422;
    --footerLinkText:           #ffffff;
    --footerText:               #ffffff;
    --headingText:              #494949;
    --bodyText:                 #494949;
    --disclaimerText:           #74747B;
    --mobile-primary-bg:        #eeeeee;


    --primaryType:             'GothamBook', sans-serif;
    --secondaryType:           'TradeGothic', sans-serif;
    --tertiaryType:            'TradeGothicLight', 'sans-serif';
}

