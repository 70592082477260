.primary-button,
.secondary-button {
  border: 2px solid $primaryButtonBorder;
  background: $primaryButtonBg;
  cursor: pointer;
  padding: 8px 10px;
  text-transform: uppercase;
  color: #ffffff;
  font-size: rem(22px);
  font-family: $secondaryType;
  position: relative;
  min-width: 200px;
  text-align: center;
  -webkit-appearance: none;

  &:hover {
    background: $primaryButtonHover;
    border: 2px solid $primaryButtonBorder;
    color: $primaryButtonHoverText;
    text-decoration: none;
  }

  @media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    height: 46px;
    padding: 0 10px 0;
    line-height: 50px;
  }
}

.secondary-button {
  border: 2px solid $secondaryButtonBorder;
  background: $secondaryButtonBg;

  &:hover {
    background: $secondaryButtonHover;
    border: 2px solid $secondaryButtonBorder;
    color: $secondaryButtonHoverText;
  }
}
