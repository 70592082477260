$black:                         var(--black);
$inputFieldsBorder:             var(--inputFieldsBorder);
$invalidState:                  var(--invalidState);
$inputFieldsFocus:              var(--inputFieldsFocus);
$linkText:                      var(--linkText);
$primaryButtonBg:               var(--primaryButtonBg);
$primaryButtonBorder:           var(--primaryButtonBorder);
$primaryButtonHover:            var(--primaryButtonHover);
$primaryButtonHoverText:        var(--primaryButtonHoverText);
$primaryButtonText:             var(--primaryButtonText);
$secondaryButtonBg:             var(--secondaryButtonBg);
$secondaryButtonBorder:         var(--secondaryButtonBorder);
$secondaryButtonHover:          var(--secondaryButtonHover);
$secondaryButtonHoverText:      var(--secondaryButtonHoverText);
$secondaryButtonText:           var(--secondaryButtonText);
$headerBg:                      var(--headerBg);
$headerAccent:                  var(--headerAccent);
$headerLinkText:                var(--headerLinkText);
$white:                         var(--white);
$footerBg:                      var(--footerBg);
$footerLinkText:                var(--footerLinkText);
$footerText:                    var(--footerText);
$headingText:                   var(--headingText);
$bodyText:                      var(--bodyText);
$disclaimerText:                var(--disclaimerText);
$mobile-primary-bg:             var(--mobile-primary-bg);
$desktop-primary-bg:            var(--desktop-primary-bg);
$secondary-bg:                  var(--secondary-bg);

$primaryType:                   var(--primaryType);
$secondaryType:                 var(--secondaryType);
$tertiaryType:                  var(--tertiaryType);

#root {
    --black:                    #000000;
    --inputFieldsBorder:        #231f20;
    --invalidState:             #e64b38;
    --inputFieldsFocus:         #ff0000;
    --linkText:                 #a53422;
    --primaryButtonBg:          #65a019;
    --primaryButtonBorder:      #65a019;
    --primaryButtonHover:       #40c2cc;
    --primaryButtonHoverText:   #e64b38;
    --primaryButtonText:        #ffffff;
    --secondaryButtonBg:        #3a3a3c;
    --secondaryButtonBorder:    #3a3a3c;
    --secondaryButtonHover:     #ffffff;
    --secondaryButtonHoverText: #3a3a3c;
    --secondaryButtonText:      #ffffff;
    --headerBg:                 #ffffff;
    --headerAccent:             #aaaaaa;
    --headerLinkText:           #000000;
    --headerLinkText:           #eeeeee;
    --white:                    #ffffff;
    --footerBg:                 #eeeeee;
    --footerLinkText:           #000000;
    --footerText:               #000000;
    --headingText:              #000000;
    --bodyText:                 #000000;
    --disclaimerText:           #74747B;
    --mobile-primary-bg:        #eeeeee;
    --desktop-primary-bg:       #eeeeee;
    

    --primaryType:             Arial, Helvetica, sans-serif;
    --secondaryType:           Arial, Helvetica, sans-serif;
    --tertiaryType:            Arial, Helvetica, sans-serif;
}