h1 {
  font-family: $secondaryType;
  font-size: rem(36px);
  color: $headingText;
  text-transform: uppercase;

  @media screen and (max-width: 767px) {
    font-size: rem(26px);
  }
}

label,
span {
  font-family: $primaryType;
}

input[type='checkbox'] {
  & + label {
    font-family: $primaryType;
  }
}

a {
  text-decoration: none;
  font-family: $primaryType;
  color: $linkText;

  &:hover {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: $linkText;
  }
}

.section-title {
  text-transform: uppercase;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.fetchsl-container {
  label, .info-wrapper, span, select {
    font-family: $tertiaryType;
  }

  .form-wrapper {
    label, a {
      font-family: $tertiaryType;
    }
  }

  .disclaimer-component {
    p, a {
     font-family: $tertiaryType;
    }
  }
}

.fetchsl-benefitsDisclaimer {
  .disclaimer-component {
    p, a {
     font-family: $tertiaryType;
    }
  }
}

.font-tertiary {
  font-family: $tertiaryType;
}

.fetchsl-info {
  font-size: 20px;
}

