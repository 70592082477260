.button-wrapper {
    span {
        font-family: $secondaryType;
        text-transform: uppercase;
    }
}

.proceed-wrapper {
    span {
        font-size: rem(14px);
    }
}