.button-list-component {
  display: flex;
  justify-content: center;

  .button-wrapper {
    margin-right: 100px;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-block;
      min-width: 200px;
      text-align: center;
    }

    @media screen and (max-width: 991px) {
      margin-right: 50px;
      text-align: center;
    }

    @media screen and (max-width: 767px) {
      margin: 0 0 32px;

      &:last-child {
        margin: 0;
      }
    }
  }

  .info {
    display: block;
    margin-bottom: 12px;
  }

  @media screen and (max-width: 767px) {
    margin-right: 0;
    text-align: center;
    flex-direction: column;
  }
}
