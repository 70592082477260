@font-face {
   font-family: 'Trade Gothic';
   src: url('/assets/auntieannes/fonts/TradeGothicLTStd-BdCn20.eot');
   src: url('/assets/auntieannes/fonts/TradeGothicLTStd-BdCn20.woff2') format('woff2'), url('/assets/auntieannes/fonts/TradeGothicLTStd-BdCn20.woff') format('woff'), url('/assets/auntieannes/fonts/TradeGothicLTStd-BdCn20.ttf') format('truetype'), url('/assets/auntieannes/fonts/TradeGothicLTStd-BdCn20.svg#TradeGothicLTStd-BdCn20') format('svg'), url('/assets/auntieannes/fonts/TradeGothicLTStd-BdCn20.eot?#iefix') format('embedded-opentype');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'Trade Gothic Next';
   src: url('/assets/auntieannes/fonts/TradeGothicNextLTPro-HvCm.eot');
   src: url('/assets/auntieannes/fonts/TradeGothicNextLTPro-HvCm.woff2') format('woff2'), url('/assets/auntieannes/fonts/TradeGothicNextLTPro-HvCm.woff') format('woff'), url('/assets/auntieannes/fonts/TradeGothicNextLTPro-HvCm.eot?#iefix') format('embedded-opentype');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'Klinic Slab';
   src: url('/assets/auntieannes/fonts/KlinicSlabBook.otf');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'Klinic Slab';
   src: url('/assets/auntieannes/fonts/KlinicSlabBook.otf');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'Klinic Slab';
   src: url('/assets/auntieannes/fonts/KlinicSlabBook.otf');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'Mont';
   src: url('/assets/auntieannes/fonts/Mont-Heavy.otf');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'FS Pimlico';
   src: url('/assets/auntieannes/fonts/FSPimlico-Regular.otf');
   font-weight: normal;
   font-style: normal;
}

@import 'shared/variables';

.auntieannes-theme,
.csaunsch-theme,
.csaunjam-theme,
.muttggenroll-theme {
   @import "shared/typography";
   @import "shared/buttons";
   @import 'shared/footer';
   @import "shared/header";

   @import "components/hero";
   @import "components/button-list";
   @import "components/card";
   @import "components/disclaimer";
   @import "components/spotlight";
}

.csaunsch-theme,
.csaunjam-theme {
   .hero-component {
      .content-wrapper {
         background: transparent;
         color: var(--secondaryButtonText);

         .title-image-wrapper,
         .description {
            margin-bottom: rem(10px);
         }

         .title {
            color: var(--secondaryButtonText);
            font-family: var(--quaternaryType);
         }

         .description {
            font-size: 3rem;
            font-weight: bold;
         }

         .description,
         .note {
            font-family: var(--quinaryType);
         }
      }
   }

   .form-wrapper {
      input,
      label,
      select,
      span,
      .primary-link {
         font-family: var(--quinaryType);
      }

      .primary-button {
         font-family: var(--quaternaryType);
      }
   }

   .main-header {
      .header-content {
         .logo-wrapper {
            a {
               .logo {
                  max-width: 100%;
               }
            }
         }
      }
   }

   h1,
   h3 {
      font-family: var(--quaternaryType);
   }
}

.landing-head {
   padding-bottom: 40px;
   text-align: center;

	h1 {
      font-family: var(--quaternaryType);
	}
  
	.landing-process {
      font-size: rem(24px);
      font-family: var(--quinaryType);
	}
	
	.landing-completed-message {
      font-size: rem(16px);
      font-family: var(--quinaryType);
	}
}

.signin-title-message, .signup-title-message {
   font-size: rem(28px);
   padding-bottom: 16px;
   font-family: var(--quinaryType);
   font-weight: bold;
 }

 .aarpaa-container {
   .section-title {
      max-width: none;
   }

   .section-subtitle {
      font-size: rem(22px);
      text-align: center;
   }
 }