.hero-component {
    .info {
        text-transform: uppercase;
        font-size: rem(14px);
        font-family: $secondaryType;
    }

    .content-wrapper {
        max-width: 700px;

        .title {
            font-size: rem(48px);

            @media screen and (max-width: 767px) {
                font-size: rem(24px);
            }
        }

        p {
            line-height: 1.3;
        }
    }
}