.main-footer {
    .content-wrapper {
        .footer-text {
            font-family: $primaryType;
            font-size: rem(14px);
        }

        .footer-link-list {
            li {
                a {
                    font-family: $secondaryType;
                    font-weight: 400;
                    font-size: rem(14px);

                    @media screen and (max-width: 767px) {
                        font-size: rem(11px);
                    }
                }
            }
        }
    }
}