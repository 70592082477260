.primary-button,
.secondary-button {
    background-color: $primaryButtonBg;
    border: 2px solid $primaryButtonBorder;
    color: $primaryButtonText;
    cursor: pointer;
    font-size: rem(16px);
    padding: 12px 34px;
    text-decoration: none;
    font-weight: 700;
    transition: all .2s ease;
    outline: 0;
    border-radius: 500px;
}

.primary-button:hover,
.primary-button:focus {
    background-color: $primaryButtonHover;
    border: 2px solid $primaryButtonHover;
    outline: none;
    color: $primaryButtonHoverText;
}

.secondary-button {
    background: $secondaryButtonBg;
    border: 2px solid $secondaryButtonBorder;
    color: $secondaryButtonText;
}

.secondary-button:hover,
.secondary-button:focus {
    background-color: $secondaryButtonHover;
    border: 2px solid $secondaryButtonHover;
    color: $secondaryButtonHoverText;
}