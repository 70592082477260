.card-component {
  text-align: center;
  max-width: 230px;
  margin: 0 auto 32px;

  @media screen and (max-width: 767px) {
    margin-bottom: 32px;
  }

  &.secondary-style {
    max-width: 340px;
  }

  .image-wrapper {
    margin-bottom: 20px;
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 128px;
    }
  }

  .content-wrapper {
    line-height: 1.3;

    .title {
      color: $headingText;
      margin-bottom: 20px;
      font-family: $secondaryType;
      font-size: rem(26px);
    }

    .description {
      font-size: rem(18px);
      margin-bottom: 16px;
    }
    .note {
      font-size: rem(14px);
      color: $disclaimerText;
    }
  }
}
