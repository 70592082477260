.auntieannes-theme.channels-theme {
  --inputFieldsBorder: #1c2529;
  --invalidState: #e64b38;
  --inputFieldsFocus: #c0461b;
  --linkText: #1b5196;
  --primaryButtonBg: #000f9f;
  --primaryButtonBorder: #000f9f;
  --primaryButtonHover: #fda30c;
  --primaryButtonHoverText: #000000;
  --primaryButtonText: #292a4a;
  --secondaryButtonBg: #1b5196;
  --secondaryButtonBorder: #1b5196;
  --secondaryButtonHover: #edac1a;
  --secondaryButtonHoverText: #292a4a;
  --secondaryButtonText: #ffffff;
  --headerAccent: #1b5196;
  --headerLinkText: #494949;
  --footerBg: #ffffff;
  --footerLinkText: #323232;
  --footerText: #323232;
  --headingText: #000000;
  --bodyText: #1c2529;
  --disclaimerText: #74747B;
  --mobile-primary-bg: #ffffff;
  --desktop-primary-bg: #cfecf8;

  --primaryType: 'Trade Gothic', Arial, Helvetica, sans-serif;
  --secondaryType: 'Klinic Slab', Arial, Helvetica, sans-serif;
  --tertiaryType: 'Trade Gothic Next', Arial, Helvetica, sans-serif;
  --quaternaryType: 'Mont', Arial, Helvetica, sans-serif;
  --quinaryType: 'FS Pimlico', Arial, Helvetica, sans-serif;
}