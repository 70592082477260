@font-face {
	font-family: 'gotham-bold-web';
	src:
		url('/assets/cinnabon/fonts/gotham_bold-webfont.woff2') format('woff2'),
		url('/assets/cinnabon/fonts/gotham_bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'gotham-web';
	src:
		url('/assets/cinnabon/fonts/gotham_book-webfont.woff2') format('woff2'),
		url('/assets/cinnabon/fonts/gotham_book-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'thirstyrough-web';
	src:
		url('/assets/cinnabon/fonts/thirstyroughbol-webfont.woff2') format('woff2'),
		url('/assets/cinnabon/fonts/thirstyroughbol-webfont.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@import 'shared/variables';

.cinnabon-theme,
.cscinmca-theme,
.cscinmoe-theme {
    @import "shared/typography";
    @import "shared/buttons";
    @import 'shared/footer';
    @import "shared/header";

    @import "components/hero";
    @import "components/button-list";
    @import "components/card";
    @import "components/disclaimer";
    @import "components/spotlight";
}

.cinnabon-theme {
	&.success-theme {

		.section-title {
			color: var(--delicious-pink);
			text-transform: none;
			font-family: var(--quaternaryType);
		}

		.section-subtitle, .card-component, .title, p {
			color: var(--midnight-blue);
			font-family: var(--quinaryType);
		}

}

	&.aarp-theme {
		.title, p {
			color: var(--midnight-blue);
			font-family: var(--quinaryType);
		}

		.landing-head {
			padding-bottom: rem(40px);
			text-align: center;
		 
			 h1 {
				color: var(--delicious-pink);
				text-transform: none;
				font-family: var(--quaternaryType);
			 }
			 
			 .landing-process {
				color: var(--midnight-blue);
				font-size: rem(24px);
			 }
			 
			 .landing-completed-message {
				color: var(--midnight-blue);
				 font-size: rem(16px);
			 }
		 }
		 
		 .signin-title-message, .signup-title-message {
			color: var(--midnight-blue);
			font-size: rem(28px);
			padding-bottom: 16px;
			font-family: var(--quinaryType);
			font-weight: bold;
			}
	}

	&.link-account-theme {
		.note-wrapper {
			color: var(--midnight-blue);
			font-family: var(--quinaryType);
		}
	}

	&.tmobiletuesdaysoct15-theme {
		h1, h2 {
			color: var(--delicious-pink);
			font-family: var(--quaternaryType);
		}

		.info-wrapper {
			font-family: var(--quinaryType);
			font-size: rem(18px);
	
			&:first-of-type {
				font-size: rem(22px);
			}
		}
	
		h3 {
				font-family: var(--quinaryType);
				font-weight: 700;
				font-size: rem(22px);
			}
	
		.form-wrapper {
			label, span, a {
				font-family: var(--quinaryType);
			}
		}
	
		.disclaimer-component {
			margin: 64px 0 0;
			max-width: 100%;
			p {
				text-align: left;
				font-family: var(--quinaryType);
			}
		}
	
		ol {
			font-family: var(--quinaryType);
			font-size: 1.125rem;
		}
	}
}
