.cinnabon-theme.fetch-theme,
.cinnabon-theme.fetch-cross-theme,
.cscinmca-theme ,
.cscinmoe-theme {
    --inputFieldsBorder:        #1c2529;
    --invalidState:             #e64b38;
    --inputFieldsFocus:         #c0461b;
    --linkText:                 #d7572b;
    --primaryButtonBg:          #071D49;
    --primaryButtonBorder:      #071D49;
    --primaryButtonHover:       #030f29;
    --primaryButtonHoverText:   #ffffff;
    --primaryButtonText:        #ffffff;
    --secondaryButtonBg:        #4e6e60;
    --secondaryButtonBorder:    #4e6e60;
    --secondaryButtonHover:     #6f9987;
    --secondaryButtonHoverText: #ffffff;
    --secondaryButtonText:      #ffffff;
    --headerAccent:             #6fa287;
    --headerLinkText:           #494949;
    --footerBg:                 #ffffff;
    --footerLinkText:           #66656b;
    --footerLinkTextSecondary:  #523428;
    --footerText:               #1c2529;
    --headingText:              #ffffff;
    --headingTextSecondary:     #071D49;
    --bodyText:                 #1c2529;
    --disclaimerText:           #74747B;
    --mobile-primary-bg:        #fafafa;
    --desktop-primary-bg:       #fafafa;
    --primary-border:           #52342880;

    --primaryType:              'urbane-rounded', sans-serif;
    // --secondaryType:           'Bourton', sans-serif;
    --tertiaryType:             'vc-henrietta', sans-serif;
}

