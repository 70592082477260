.main-footer {
  .footer-link-list {
    li {
      a {
        text-transform: capitalize;
        font-weight: 500;
      }
    }
  }
  .additional-links {
    display: flex;

    li {
      margin-right: rem(12px);

      a {
        color: #4E6E60;
        text-transform: lowercase;
      }
    }
  }

  .fcs-third-party {
    svg {
      margin-left: rem(4px);
    }
  }
}