.main-header {
  .header-content {
    .logo-wrapper {
      a {
        .logo {
          max-width: 220px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      justify-content: space-between;
    }
  }
}

&.aarp-theme {
  @media screen and (max-width: 767px) {
    .main-header {
      .header-content {
        justify-content: space-between;
      }
     }
  }
}

&.tmobiletuesdaysoct15-theme {
  .header-content {
    .logo-wrapper {
      a {
        .logo {
          max-width: 240px;
        }
      }
    }
  }
}