.main-header {
  .header-content {
    .logo-wrapper {
      a {
        .logo {
          max-width: none;

          @media screen and (max-width: 767px) {
            max-width: 250px;
            max-height: 46px;
          }
        }
      }
    }
  }
}