.main-footer {
  background-color: $footerBg;
  font-size: rem(16px);

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 15px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }    
     
    .footer-text {
      color: $footerText;
    }
  }

  .footer-link-list {
    list-style: none;
    display: flex;

    
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    } 

    li {
      margin-right: 10px;
      border-right: 1px solid $footerLinkText;
      padding-right: 10px;

      @media screen and (max-width: 767px) {
        margin-top: 10px;
        padding-right: 6px;
        margin-right: 6px;
      } 

      &:last-child {
        border-right: none;
        margin-right: 0;
        padding-right: 0;
      }

      a {
        text-transform: uppercase;
        color: $footerLinkText;
        font-weight: bold;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }

        @media screen and (max-width: 767px) {
          font-size: rem(10px);
        } 
      }
    }
  }

  .footer-nav {
    .footer-link-list {
      li {
        border: none;
      }

      a {
        color: $linkText;
        text-transform: capitalize;
        font-weight: 500;
      }
    }
  }
}

