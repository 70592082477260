.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.mt-small {
  margin-top: 16px;
}

.mt-medium {
  margin-top: 32px;
}

.mt-mobile-medium {
  @media screen and (max-width: 767px) {
    margin-top: 32px;
  }
}

.mt-large {
  margin-top: 64px;

  @media screen and (max-width: 767px) {
    margin-top: 32px;
  }
}

.mb-small {
  margin-bottom: 16px;
}

.mb-medium {
  margin-bottom: 32px;

  @media screen and (max-width: 767px) {
    margin-bottom: 16px;
  }
}

.mb-large {
  margin-bottom: 64px;
}

.pt-small {
  padding-top: 16px;
}

.pt-medium {
  padding-top: 32px;
}

.pb-medium {
  padding-bottom: 32px;
}

.h-align-end {
  display: flex;
  justify-content: flex-end;
}

.h-align-start {
  display: flex;
  justify-content: flex-start;
}

.form-wrapper {
  max-width: 800px;

  &.fill {
    max-width: 100%;
  }
}

.success-wrapper {
  min-height: 400px;
}

.captcha-wrapper {
  @media screen and (max-width: 767px) {
    margin: 16px 0 32px;
  }
}

.info-wrapper {
  margin-bottom: 24px;

  &:empty {
    padding-bottom: 16px;
  }

  a {
    &:before {
      @media screen and (max-width: 767px) {
        content: "";
        display: block;
      }
    }
  }
}

.note-wrapper {
  max-width: 70%;
  margin-top: 16px;
  margin-bottom: 16px;
  line-height: 1.3;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    margin-bottom: 32px;
  }
}

.button-wrapper {
  margin-top: 16px;
  @media screen and (max-width: 767px) {
  }

  span {
    display: inline-block;
    margin-bottom: 6px;
  }
}

.proceed-wrapper {
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  span {
    display: inline-block;
    max-width: 200px;
    padding-left: 16px;

    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-top: 6px;
    }
  }
}

.mobile-primary-bg {
  @media screen and (max-width: 767px) {
    background: $mobile-primary-bg;
    padding: 32px 0;
  }
}

.primary-bg {
  background: $mobile-primary-bg;
}

.secondary-bg {
  background: $secondary-bg;
}

.desktop-primary-bg {
  background: $desktop-primary-bg;

  @media screen and (max-width: 767px) {
    background: none;
  }
}

.visible-desktop {
  display: block;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.fs-medium {
  font-size: rem(22px);
}

.three-col-wrapper {
  &.row {
    flex-wrap: wrap;

    .col {
      flex-basis: 33.33%;

      @media screen and (max-width: 767px) {
        flex-basis: 100%;
      }
    }
  }

  &.center {
    justify-content: center;
  }
}

.text-center {
  text-align: center;
}

.text-line-normal {
  line-height: 1.6;
}

.text-bold {
  font-weight: bold;
}

.faint-text {
  color: var(--faintText);
  font-size: rem(12px);
}

.text-large {
  font-size: rem(20px);
}

// GTM iframe fix
img[src^="https://secure.adnxs.com"],
img[src^="https://imp.control.kochava.com"],
img[src^="https://insight.adsrvr.org"],
img[src^="https://bob.dmpxs.com"],
img[src^="https://servedby.flashtalking.com"] {
  position: absolute;
  bottom: 0;
}

.rich-text {
  p {
    margin-top: rem(34px);
    line-height: 1.6;
  }
}

.cookieSettingButton {
  img {
    padding-left: 6px;
    width: 30px;
  }
}

img {
  &.responsive {
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.numbered-list {
  list-style: decimal;
  font-family: $secondaryType;
  list-style-position: inside;
  font-size: 1.125rem;

  .app-link-component {
    margin-bottom: rem(32px);
  }
}